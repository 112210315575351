import React from "react"
import { Router } from "@reach/router"
import WorksDetailPage from "./works-detail"

const App = () => (
  <Router>
    <WorksDetailPage path="works/:slug" />
  </Router>
)

export default App
